import React from "react"

import { PageRendererProps } from "gatsby"

import { WrappedBase } from "../../shared-components/Shared/WrappedBase"
import { UserContext } from "../../shared-components/Shared/UserProvider"

import SignUpPage from "../../views/SignUp"

import config from "../../../config.json"
import { amplitude, registerButtonClick } from "../../utils/amplitude"

const pageName = "signup_page"

const IndexPage: React.FunctionComponent<PageRendererProps> = ({
  location,
}) => {
  const [hasSeen, see] = React.useState(false)

  return (
    <WrappedBase
      metaTitle="Habito | Sign up"
      metaDescription="Sign up to Habito - the UK's free online mortgage broker, making mortgage worries history. Our smart technology searches the whole market - thousands of mortgage products from over 90 lenders - to get you the perfect mortgage."
      canonicalUrl="https://www.habito.com/sign-up"
      noIndex={true} // signup page should never be indexed
      pageName={pageName}
      intercom={false}
      config={config}
      head={null}
      amplitudeBeforeUnloadCallback={logEvent => {
        if (!hasSeen) {
          logEvent({ title: "bounced_signup", payload: {} })
          see(true)
        }
      }}
    >
      <UserContext.Consumer>
        {user => (
          <SignUpPage
            isLoggedIn={Boolean(user)}
            location={location}
            registerButtonClick={registerButtonClick(pageName)}
            amplitudeClient={amplitude}
          />
        )}
      </UserContext.Consumer>
    </WrappedBase>
  )
}

export default IndexPage
